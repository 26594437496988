import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-presige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="guardian-paramount"></a><h3>GUARDIAN PARAMOUNT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d10.
    <a id="guardian-paramount-requirements"></a><h6>Requirements</h6>
To qualify to become a guardian paramount, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span>
+15. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
13 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#lightning-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Lightning Reflexes</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a href="epicFeats.html#blinding-speed" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blinding
Speed</a>, <a href="epicFeats.html#superior-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Superior Initiative</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Uncanny
dodge, evasion. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The guardian paramount&#8217;s class skills (and the key ability for
each skill) are <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a> (Cha), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
(Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> (Wis). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
4 + Int modifier. </p>
    <a id="table-the-guardian-paramount"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Guardian Paramount</span>
    <span style={{
      "fontWeight": "bold"
    }}>
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, uncanny dodge enabler 3/day
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Evasive
preceptor 1/day</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Protective aura</span> 1/day</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, uncanny dodge enabler 4/day</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Adjust
probability 2/day, evasive preceptor 2/day</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Call back</span> 1/day, <span style={{
              "fontStyle": "italic"
            }}>protective aura</span> 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, uncanny dodge enabler 5/day</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Evasive
preceptor 3/day, adjust probability 3/day
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Protective aura</span> 3/day</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat, uncanny dodge enabler 6/day</td>
        </tr>
      </tbody>
    </table>
    <a id="guardian-paramount-class-features"></a><h6>Class Features</h6>
The following are class features of the guardian paramount prestige
class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
A guardian paramount is proficient with all simple and martial weapons,
all armor, and shields. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The
guardian paramount gets a bonus feat at 1st level and an additional
bonus feat every three levels thereafter. These bonus feats must be
selected from the following list: Bulwark of Defense, Combat Archery,
Damage Reduction, Dexterous Fortitude, Dexterous Will, Epic Dodge, Epic
Fortitude, Epic Reflexes, Epic Reputation, Epic Skill Focus, Epic
Speed, Epic Toughness, Epic Trapfinding, Epic Will, Exceptional
Deflection, Fast Healing, Great Dexterity, Improved Combat Reflexes,
Improved Sneak Attack, Improved Spell Resistance, Infinite Deflection,
Legendary Climber, Lingering Damage, Mobile Defense, Perfect Health,
Reflect Arrows, Self-Concealment, Sneak Attack of Opportunity,
Spellcasting Harrier, and Uncanny Accuracy. </p>
    <a id="guardian-paramount-uncanny-dodge-enabler"></a><p><span style={{
        "fontWeight": "bold"
      }}>Uncanny Dodge Enabler (Ex):</span>
The guardian paramount must have the uncanny dodge class feature to
qualify for the prestige class, so at a minimum the guardian paramount
has the extraordinary ability to retain his or her Dexterity bonus to
AC (if any) regardless of being caught flat-footed or struck by an
invisible attacker. It is possible that the character has higher-level
aspects of the uncanny dodge ability. Whatever the level of uncanny
dodge attained by the character, the guardian paramount can extend the
features of his or her uncanny dodge ability to include any one
creature he or she designates within 5 feet of him or her (he or she
can designate a creature or change designations as a free action once
per round). The guardian paramount can extend his or her uncanny dodge
ability three times per day at 1st level, plus one additional time per
day every three levels thereafter. </p>
    <a id="guardian-paramount-evasive-preceptor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Evasive Preceptor (Ex):</span>
The guardian paramount must have the evasion class feature, which
allows the character to take no damage from an area attack with a
successful Reflex save. He or she may also have improved evasion,
though this is not a prerequisite. The character can extend evasion or
improved evasion to include any one creature he or she designates
within 5 feet of him or her. The guardian paramount can extend his or
her evasion ability once per day at 2nd level, plus one additional time
per day every three levels thereafter. </p>
    <a id="guardian-paramount-protective-aura"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Protective
Aura (Sp):</span> Starting at 3rd level, the guardian paramount can use
a special form of shield other once per day, plus one additional time
per day every three levels thereafter. When a guardian paramount
creates a protective aura, the transferred wounds are dealt to the
guardian paramount as subdual damage, not normal damage, as with the
shield other spell. Otherwise, the guardian paramount&#8217;s protective aura
functions like the shield other spell cast by an 8th-level cleric. </p>
    <a id="guardian-paramount-adjust-probability"></a><p><span style={{
        "fontWeight": "bold"
      }}>Adjust Probability (Ex):</span>
On reaching 5th level, the guardian paramount gains the ability to
affect probability twice per day, plus one additional time per day per
three levels thereafter. The guardian paramount can force a reroll of
one attack roll, check, or saving throw that another creature within 25
feet-friend or enemy-just made. The guardian paramount can find out
whether the attack roll, check, or save would have succeeded before
using this ability. The recipient must take the second roll, whether
it&#8217;s better or worse than the original roll. The use of this ability
takes place outside the normal initiative order, but the paramount
guardian can&#8217;t use it if he or she is flat-footed or unable to see the
situation resulting in the roll. The guardian paramount must decide
whether to reroll as soon as the result of the attack roll, check, or
save is known. </p>
    <a id="guardian-paramount-call-back"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Call Back
(Sp):</span> On reaching 6th level, the guardian paramount gains the
ability to return a dead creature that he or she has previously used
any of his or her other class abilities on back to life, as if he or
she had cast true resurrection as a 20th-level cleric. The character
can use this ability once per day, plus one additional time per day
every six levels. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      